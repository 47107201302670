@charset "utf-8";

page{
	position: relative;
	background: #fff;
	display: block;
	margin: 0 auto;
	font-size: 16px;
	page-break-inside: avoid;
}

page[size="A4"]{
	width: 1020px;
}

page[size="A4"][layout="landscape"]{
	width: 1420px;
}

page p{
	font-size: 18px;
}

tmp-h1{
	display: block;
	font-size: 26px;
	letter-spacing: 2px;
}

tmp-h2{
	display: block;
	font-size: 20px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: bold;
	margin-top: 0.5cm;
	margin-bottom: 0.5cm;
}

tmp-h3{
	width: 100%;
	display: block;
	font-size: 20px;
	letter-spacing: 1px;
	margin-top: 0.5cm;
	margin-bottom: 0.5cm;
	border-bottom: 2px solid #999;
}

tmp-h3b{
	width: 100%;
	display: block;
	font-size: 18px;
	margin-top: 0.5cm;
	margin-bottom: 0.5cm;
	border-bottom: 2px solid #999;
}

tmp-h4{
	width: 100%;
	display: block;
	font-size: 18px;
	font-weight: bold;
	height: 1.5em;
}

.tmp-small tmp-h4{
	font-size: 16px;
}

.tmp-small tmp-field label, .tmp-small p{
	font-size: 15px;
}

.tmp-small tmp-field{
	margin: 0;
}

tmp-row{
	display: flex;
	flex-direction: row;
}

tmp-line{
	display: block;
	width: 100%;
	margin-top: 0.5cm;
	padding-top: 0.5cm;
	border-top: 1px solid #999;
}

tmp-column{
	display: flex;
	flex-direction: column;
	flex: 0 1 100%;
}

tmp-column + tmp-column{
	margin-left: 0.5cm;
	padding-left: 0.5cm;
	border-left: 1px solid #999;
}

br + tmp-column{
	margin-left: 0.25cm;
}

.tmp-fix{
	flex: 0 0 none;
}

tmp-field{
	display: inline-flex;
	flex-direction: row;
	position: relative;
	white-space: nowrap;
	width: 100%;
	height: 1.5em;
	margin-bottom: 0.25cm;
	z-index: 5;
}

tmp-field + tmp-field{
	z-index: 4;
}

tmp-row > tmp-field:not(:last-child){
	margin-right: 1em;
}

tmp-field div{
	position: relative;
	left: 0;
	padding: 0.25em;
	width: 100%;
	background: #eee;
}

tmp-field div:after{
	position: absolute;
	content: "";
	left: 0;
	top: 0.05cm;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid #999;
}

.tmp-white tmp-field div{
	background: #fff;
}

tmp-field.tmp-checkbox{
	justify-content: flex-end;
	align-self: center;
	text-align: right;
}

tmp-field.tmp-checkbox div{
	flex-shrink: 0;
	width: 0.5cm;
}

tmp-field.tmp-picture{
	flex-direction: column;
	height: auto;
	text-align: center;
}

tmp-field .tmp-icon-pain{
	margin: auto;
	font-size: 60px;
}

tmp-field label{
	padding: 0.25em;
	white-space: nowrap;
}

tmp-field.tmp-wrap{
	height: auto;
	align-items: center;
}

.tmp-wrap label{
	white-space: normal;
}

.tmp-wrap div{
	height: 1em;
}

.tmp-scale, .tmp-scale tmp-field{
	height: auto;
}

.tmp-scale label{
	margin: auto;
	width: 100%;
	font-size: 20px;
	text-align: center;
	border: 1px solid #000;
}

tmp-field.label-top{
	margin-top: 0.3cm;
}

tmp-field.label-top label{
	position: absolute;
	left: 0;
	top: -1.1em;
	padding: 0;
	font-size: 12px;
}

tmp-field.label-bottom:not(:last-of-type){
	margin-bottom: 0.6cm;
}

tmp-field.label-bottom label{
	position: absolute;
	right: 0;
	bottom: -1.5em;
	padding: 0;
	font-size: 12px;
}

.tmp-table tr{
	height: 1.5rem;
}

.tmp-table tr:nth-child(even){
	background: #eee;
}

.tmp-table th, .tmp-table td{
	padding: 0;
	text-align: center;
    border-right: 1px solid #ccc;
}

.tmp-table td:first-child{
	background: #ddd;
	font-weight: bold;
	font-size: 0.7rem;
}

.tmp-compact tmp-h3, .tmp-compact tmp-h3b{
	margin-top: 0.3cm;
	margin-bottom: 0.3cm;
}

.w5{
	flex: 0 0 5%;
}

.w10{
	flex: 0 0 10%;
}

.w15{
	flex: 0 0 15%;
}

.w20{
	flex: 0 0 20%;
}

.w25{
	flex: 0 0 25%;
}

.w30{
	flex: 0 0 30%;
}

.w40{
	flex: 0 0 40%;
}

.w50{
	flex: 0 0 50%;
}

.w60{
	flex: 0 0 60%;
}

.w70{
	flex: 0 0 70%;
}

.w80{
	flex: 0 0 80%;
}

.w90{
	flex: 0 0 90%;
}

.w100{
	flex: 0 0 100%;
}

@media only print {

	@page{
		size: A4 portrait;
	}

}