@charset "utf-8";

html {
	overflow: hidden;
	margin: 0;
	background: #eee;
	height: 100%;
}

body {
	overflow: auto;
	height: 100%;
}

#root {
	display: flex;
	flex-direction: column;
}

a {
	display: inline-block;
}

p {
	font-size: 0.75rem !important;
	line-height: 1.4;
}

p + p {
	margin-top: 1em;
}

header {
	display: block;
	padding: 1rem 2rem;
	background: #fff;
}

.slider {
	position: relative;
	display: flex;
	justify-content: space-between;
	max-width: 55rem;
	height: 10rem;
	box-sizing: border-box;
	margin: auto;
}

.logo {
	display: flex;
	align-items: center;
	height: 2.25rem;
	margin-right: auto;
	z-index: 1;
}

.logo svg {
	width: auto;
	max-width: 40vw;
	height: 100%;
}

.login-box {
	margin-left: 1em;
}

.menu {
	display: flex;
	justify-content: flex-end;
	height: 2.25rem;
}

.menu-item {
	position: relative;
	display: flex;
	align-items: center;
	height: 2.25rem;
	padding: 0 0.5rem;
	letter-spacing: 1px;
	color: #888;
	cursor: pointer;
	white-space: nowrap;
	transition: all 0.2s ease-out;
}

.menu a + a {
	margin-left: 0.5em;
}

.menu-item:hover {
	color: #000;
}

.menu-item .bar {
	position: absolute;
	left: 0;
	bottom: 0;
}

.menu-item:hover .bar:before,
.menu-item:hover .bar:after {
	width: 50%;
}

.menu-item-active .purple {
	background: #80a;
	color: #fff;
}

.menu-item-active .teal {
	background: #079;
	color: #fff;
}

.menu-item-active .green {
	background: #095;
	color: #fff;
}

button {
	height: 2.25rem;
	font-size: 0.9rem;
	padding: 0 2em;
	border-radius: 2rem;
}

main {
	display: block;
	width: 100%;
	max-width: 59rem;
	box-sizing: border-box;
	margin: 0 auto;
	margin-top: -4rem;
	padding: 0 2rem;
}

main h1 {
	width: 100%;
	margin-bottom: 1rem;
	font-size: 1.5rem;
	border-bottom: 2px solid #ccc;
}

.container {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	grid-gap: 2rem;
	margin-bottom: 2rem;
}

.card {
	overflow: hidden;
	position: relative;
	top: 0;
	background: #fff;
	cursor: default;
	transition: 0.2s all ease-in-out;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.container .card p a {
	text-decoration: underline;
}

.container .card b,
.container .card i {
	font-size: 0.8rem;
}

.card-main .card-wide-head {
	padding-right: 0;
}

.card-main .card-text {
	z-index: 1;
}

.impressum .card-text {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.impressum .card-text img {
	width: 8rem;
	height: 8rem;
}

.card-main .card-text img {
	position: absolute;
	right: -3rem;
	bottom: -1rem;
	width: 25rem;
	z-index: -1;
	opacity: 0.3;
}

.card-wide {
	position: relative;
	grid-column: 1 / -1;
	overflow: hidden;
	display: flex;
}

.cards-alternating .card:nth-child(even) {
	flex-direction: row-reverse;
}

.card:hover:not(.card-wide) {
	top: -6px;
	box-shadow: 2px 2px 12px -1px rgba(0, 0, 0, 0.15), 2px 2px 24px -3px rgba(0, 0, 0, 0.3);
}

.card-wide-head {
	overflow: hidden;
	display: flex;
	align-items: center;
	min-width: 15rem;
	max-width: 15rem;
	padding: 1em;
}

.card-wide > icon {
	position: absolute;
	font-size: 17rem;
	bottom: -5rem;
	right: -5rem;
	opacity: 0.1;
	z-index: 0;
}

.teal.card-wide icon {
	color: #079;
}

.green.card-wide icon {
	color: #095;
}

.gold.card-wide icon {
	color: #d80;
}

.purple.card-wide icon {
	color: #80a;
}

.card-wide:nth-child(even) > icon {
	left: -5rem;
}

.card-wide img {
	width: auto;
	max-width: 100%;
	margin: auto;
}

.card-header {
	height: 0.5em;
}

.card-video {
	width: 100%;
}

.card-text {
	padding: 2rem;
}

.card:not(.card-wide) .card-text h2 {
	display: flex;
	align-items: center;
	margin-top: 0;
	font-size: 1.1rem;
}

.card:not(.card-wide) .card-text h2 icon {
	top: 0;
	font-size: 3em;
	margin-right: 0.5rem;
}

.card h3,
.contract h3 {
	margin-top: 2em;
}

.card h3:first-child {
	margin-top: 0;
}

.card li {
	font-size: 0.75rem;
	list-style: square inside;
	list-style-image: url(../src/img/logo_li.png);
	padding: 0.4em 0;
}

.card-ss {
	overflow: visible;
}

.card-ss > div + div {
	margin-left: 4rem;
}

.ss {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.ss div {
	align-self: center;
}

.ss p {
	font-size: 0.75rem;
}

.ss-dp p {
	margin-right: -3rem;
}

.ss-dp img {
	width: 80%;
	margin-left: -4rem;
}

.ss-tablet {
	margin-top: 2rem;
}

.ss-tablet img {
	width: 75%;
}

.ss-tablet p {
	margin-right: -1rem;
}

.ss-phone {
	margin-left: 2rem;
}

.ss-phone p {
	margin-right: 2rem;
}

.ss-phone img {
	width: 35%;
	margin-right: 1rem;
}

.ss-tp {
	margin-top: 1rem;
}

.ss-tp img {
	width: 80%;
	margin-left: -11rem;
	margin-right: -2rem;
}

.ss-tp p {
	margin-right: 6rem;
}

.quotes {
	width: 70%;
	margin: auto;
	margin-bottom: 2rem;
}

.quote {
	flex: 1 1 0;
	position: relative;
	padding: 3em;
}

.quote icon {
	position: absolute;
	top: -1.5rem;
	left: -1.5rem;
	font-size: 7rem;
	opacity: 0.1;
}

.quote i {
	font-size: 1rem;
	color: #666;
}

.quote b {
	display: block;
	margin-top: 1em;
	text-align: right;
}

.profile {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.profile + .profile {
	margin-left: 2rem;
}

.profile img {
	width: 10rem;
	z-index: 1;
}

.profile b {
	margin: 0.25rem 0;
}

.contract {
	background: #fff;
	width: 1000px;
	margin: auto;
	padding: 1rem;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
}

.contract ps-header {
	position: relative;
}

.contract ps-header img {
	height: 40px;
}

.contract ps-header h2 {
	position: absolute;
	margin: 0 auto;
	width: 100%;
	padding: 0 30%;
	box-sizing: border-box;
	text-align: center;
}

.fade-up {
	position: relative;
	min-height: fit-content;
	animation: fadeup 1s ease-in-out forwards;
}

@keyframes fadeup {
	from {
		transform: translateY(10rem);
		opacity: 0;
	}
	to {
		transform: translateY(0rem);
		opacity: 1;
	}
}

.overlay-form {
	animation: fadein 0.5s ease-in-out forwards;
}

@keyframes fadein {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.dataform {
	position: relative;
	margin: 0;
	width: 35rem;
}

.dataform-header {
    background-image:
    linear-gradient(90deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%),
    linear-gradient(270deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);
    background-blend-mode: overlay;
    background-size: cover;
}

.dialog {
	margin: 0;
}

.dataform-content {
	overflow: hidden;
	position: relative;
	padding: 2rem !important;
}

.dataform-content img.dataform-img {
	position: absolute;
	right: -2rem;
	bottom: 0rem;
	width: 30rem;
	z-index: -2;
	opacity: 0.8;
}

.block-right {
	width: 22rem;
}

.block-right ps-input {
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
}

.block-right .input-error-msg {
	position: relative;
	top: -1rem;
	display: block;
	font-size: 0.7rem;
	color: #d30;
}

.block-right .disabled {
	pointer-events: none;
	opacity: 0.5;
}

.block-right input::placeholder {
	color: #999 !important;
}

.block-right textarea {
	min-height: 4rem;
	max-height: 10rem;
	margin-bottom: 1rem;
	width: 100%;
	resize: vertical;
}

.block-right textarea:focus {
	border-color: #4040a0;
}

.close {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	color: #fff !important;
	opacity: 0.5;
	transition: 0.2s all ease-in-out;
}

.close icon {
	font-size: 1.25rem;
}

.close:hover {
	background: #fff;
	color: #4040a0 !important;
}

.confirm {
	text-align: center;
}

.confirm p {
	text-align: center;
	font-size: 0.8rem !important;
}

.confirm button {
	margin: auto;
}

.form-submit {
	display: none;
}

.dataform .disclaimer{
	margin-top: 1rem;
	font-size: 0.6rem;
	font-style: italic;
	color: #333;
}

.login-box .dataform {
	width: 24rem;
	background-color: #fff;
}

.login-box .dataform-header {
	display: none;
}

.login-box .close {
	z-index: 20;
}

.login-box .block-right {
	width: auto;
}

.login-box ps-input {
	margin-bottom: 2rem;
}

.login-box button {
	margin: auto;
}

.login-box-header {
	position: relative;
	height: 7rem;
	width: 100%;
	z-index: 11;
	overflow: hidden;
	background-color: #fff;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.login-box-header svg {
	position: absolute;
	bottom: 2rem;
	left: 2rem;
	height: 2rem;
	width: auto;
	z-index: 11;
}

.wavebg {
    position: absolute;
    width: 100%;
	height: 100%;
	background-color: #488;
	background-image:
		radial-gradient(ellipse farthest-side at 0% 0%, #80a 10%, transparent 75%),
		radial-gradient(ellipse farthest-side at 100% 0%, #095 10%, transparent 75%),
		radial-gradient(ellipse farthest-side at 100% 100%, #d80 20%, transparent 75%),
		radial-gradient(ellipse farthest-side at 0% 100%, #d30 10%, transparent 75%);
	background-size: 600% 600%;
    animation: gradient 12s ease-in-out infinite;
}

.bg1 {
    opacity: 1;
}

.bg2 {
    opacity: 0.66;
	filter: blur(1px);
}

.bg3 {
	opacity: 0.5;
	filter: blur(2px);
}

.wave {
    position: absolute;
	left: 0;
    width: 200%;
	height: 100%;
	box-sizing: border-box;
	border-bottom: 0.5rem solid #fff;
    background-repeat: repeat no-repeat;
	background-position: 0 100%;
    transform-origin: center bottom;
}

.wave1 {
	background-image: url('img/wave1.png');
	background-size: 50% 0.75rem;
	animation: move_wave 6s linear infinite;
}

.wave2 {
	background-image: url('img/wave2.png');
	background-size: 50% 1rem;
    animation: move_wave 11s linear infinite;
}

.wave3 {
	background-image: url('img/wave3.png');
	background-size: 50% 1.25rem;
	animation: move_wave 17s linear infinite;
}

@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.5)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.login-box form > div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.25rem;
	width: 100%;
	text-align: center;
	font-size: 0.8rem;
	margin-bottom: 2rem;
	color: #d30;
}

.login-box form .msg-lock {
	margin: 0;
}

.login-box input::placeholder{
    color: #999;
    transition: 0.2s all ease-in-out;
}

.login-box input:focus::placeholder{
    color: transparent !important;
}

.login-box .visibility {
	color: #999;
	cursor: pointer;
}

.login-box .visibility.inactive {
	display: none;
}

gdpr {
	position: fixed;
	display: flex;
	justify-content: center;
	bottom: 0;
	width: 100%;
	z-index: 10;
}

gdpr.closed {
	pointer-events: none;
}

.gdpr-content {
	position: relative;
	width: 50rem;
	background: rgba(50, 50, 50, 0.9);
	box-sizing: border-box;
	transform: translateY(0);
	box-shadow: -1px -1px 1rem -0.2rem rgba(0, 0, 0, 0.2), -2px -2px 2rem -0.3rem rgba(0, 0, 0, 0.3);
	transition: 0.3s all ease-in-out;
}

gdpr::after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #000;
	opacity: 0.3;
	visibility: visible;
	transition: 0.2s all ease-in-out;
	z-index: -1;
}

gdpr.closed::after{
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.closed .gdpr-content {
	transform: translateY(100%);
	box-shadow: none;
}

.gdpr-title {
	background: rgba(0, 0, 0, 0.8);
	font-size: 0.8rem !important;
}

.gdpr-content p {
	color: #eee;
	padding: 1rem;
	margin: 0;
}

.gdpr-cookies {
	display: flex;
	padding: 1rem;
	padding-top: 0;
}

.gdpr-cookies .cookie-usage {
	font-size: 0.6rem;
	color: #ccc;
}

.gdpr-cookies .cookie-controls {
	flex: 0 0 12rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: 2rem;
}

.gdpr-cookies .cookie-controls ps-slider {
	justify-content: flex-end;
	color: #fff;
	font-size: 0.8rem;
}

.gdpr-cookies .cookie-controls button {
	margin-top: 1rem;
	background: #095;
	color: #fff;
}

gdpr .disabled .close,
gdpr .disabled button {
	pointer-events: none;
	filter: grayscale(1) blur(0.1rem);
	
}

.gdpr-content a {
	color: #fff;
	text-decoration: underline;
}

.gdpr-content a:hover {
	text-decoration: none;
}

.gdpr-content .close,
.gdpr-content .open {
	position: absolute;
	transition: 0.3s all ease-in-out;
}

.closed .gdpr-content .close {
	opacity: 0;
}

.gdpr-content .open{
	opacity: 0;
	background: #333;
	pointer-events: none;
}

.closed .gdpr-content .open {
	opacity: 0.75;
	pointer-events: all;
	top: -1rem;
	height: 1rem;
}

.closed .gdpr-content .open:hover {
	top: -2rem;
	height: 2rem;
	color: #fff !important;
}

footer {
	margin-top: auto;
	background: #222;
	color: #ccc;
}

.logo-zadtech {
	height: 6rem;
	width: 6rem;
}

.footer-content {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	margin: 0 auto;
	max-width: 55rem;
	padding: 2rem;
	box-sizing: border-box;
	text-align: right;
}

.footer-text {
	display: flex;
	flex-direction: column;
	color: #999;
	padding-left: 1.5em;
	margin-left: 1.5em;
}

.footer-text + .footer-text {
	border-left: 1px solid #555;
}

.footer-text a,
.footer-text p {
	font-size: 0.7rem;
	margin: 0.25em 0;
	color: inherit;
}

.footer-text a:hover {
	color: #fff;
}

.bricks {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	width: 9rem;
	height: 9rem;
	animation: logo-spin 16s infinite ease-in-out;
}

.brick {
	position: relative;
	flex: 0 0 auto;
	width: 3rem;
	height: 3rem;
}

.br-top,
.br-left,
.br-mid,
.br-right,
.br-bot {
	background:
		radial-gradient(circle at 0% 0%, rgba(255,255,255,0.075) 20%, rgba(255,255,255,0) 100%),
		linear-gradient(0deg, rgba(0,0,0,0.075) 0%, rgba(255,255,255,0) 50%),
		linear-gradient(270deg, rgba(255,255,255,0) 30%, rgba(255,255,255,0.075) 70%, rgba(255,255,255,0) 100%),
		linear-gradient(270deg, rgba(0,0,0,0.075) 0%, rgba(255,255,255,0) 50%);
	background-blend-mode: overlay;
}

.br-top {
	background-color: #095;
	animation: logo-animation 16s infinite ease-in-out;
}

.br-left {
	background-color: #d30;
	animation: logo-animation 16s 0.1s infinite ease-in-out;
}

.br-mid {
	background-color: #333;
	animation: logo-animation 16s 0.18s infinite ease-in-out;
}

.br-right {
	background-color: #d80;
	animation: logo-animation 16s 0.24s infinite ease-in-out;
}

.br-bot {
	background-color: #80a;
	animation: logo-animation 16s 0.28s infinite ease-in-out;
}

@keyframes logo-animation {
	23.5% {
		transform: scale(0.7);
		opacity: 0;
	}

	21%,
	26.5% {
		transform: scale(1);
		opacity: 1;
	}

	24.5% {
		transform: scale(1.25);
		opacity: 0;
	}

	68% {
		transform: scale(0.75);
	}

	66%,
	70% {
		transform: scale(1);
	}

	69% {
		transform: scale(1.15) rotateY(90deg);
	}

	70.5% {
		transform: scale(0.9);
	}

	71% {
		transform: scale(1);
	}
}

@keyframes logo-spin {
	0%,
	21% {
		transform: rotate(0deg);
	}

	26.5%,
	67% {
		transform: rotate(360deg);
	}

	70.5%,
	100% {
		transform: rotate(720deg);
	}
}

/* Timeline & Roadmap */

.timeline {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 0;
	overflow-x: auto;
}

.timeline .year::after {
	content: '';
	position: absolute;
	left: 0.5rem;
	width: 1px;
	height: 100%;
	background-color: #666;
}

.timeline .year {
	position: relative;
	display: flex;
	flex-direction: column;
	border-top: 2px solid #666;
	box-sizing: border-box;
}

.timeline .year:nth-last-child(2) {
	border-bottom: 2px solid #666;
}

.timeline .month {
	position: relative;
	width: 1rem;
	height: 4rem;
	box-sizing: border-box;
}

.timeline .month + .month {
	border-top: 1px solid #999;
}

.timeline .year-label {
	position: absolute;
	display: flex;
	align-items: center;
	width: auto;
	top: -1rem;
	left: -4rem;
	padding: 0.25rem 0.5rem;
	box-sizing: border-box;
	border: 1px solid #ccc;
	background-color: rgba(255,255,255,0.9);
	text-align: center;
	z-index: 7;
}

.release {
	position: absolute;
	width: 18rem;
	left: -24rem;
	z-index: 1;
}

.release.alt {
	left: auto;
	right: -24rem;
}

.release .-header {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	color: #fff;
	background-image:
		radial-gradient(circle at 0% 0%, rgba(255,255,255,0.1) 20%, rgba(255,255,255,0) 100%),
		linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 50%),
		linear-gradient(270deg, rgba(255,255,255,0) 30%, rgba(255,255,255,0.1) 70%, rgba(255,255,255,0) 100%),
		linear-gradient(270deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 50%);
	background-blend-mode: overlay;
}

.release .-header::after {
	content: '';
	position: absolute;
	top: 0;
	left: 6.5rem;
	height: 1px;
	width: 100%;
	background-color: inherit;
	z-index: -1;
}

.release.alt .-header::after {
	left: -6.5rem;
}

.release .-header .version {
	margin-left: 0.5rem;
	font-size: 0.9rem;
}

.release .-header .date {
	font-size: 0.7rem;
	margin-left: auto;
}

.release .content {
	width: 100%;
	padding: 0.5rem;
	box-sizing: border-box;
	background-color: #eee;
	border: 1px solid #ccc;
	border-top: none;
	font-size: 0.7rem;
}

.release b {
	display: block;
	font-size: inherit;
}

.release ul {
	margin-top: 0.4em;
}

.release li {
	font-size: inherit;
	padding: 0.2em 0;
}


@media screen and (max-width: 860px) {
	.slider {
		flex-wrap: wrap;
		justify-content: flex-end;
		width: 100%;
		padding-bottom: 5rem;
	}

	.menu {
		justify-content: space-between;
		margin-top: auto;
	}

	.menu-item {
		letter-spacing: 0.5px;
	}

	.login-box {
		position: absolute;
		margin: 0;
	}

	button {
		margin: 0;
	}

	.gdpr-content {
		width: 100%
	}

	.timeline {
		align-items: flex-end;
	}

	.timeline .month {
		height: 10rem;
	}

	.release.alt {
		right: auto;
		left: -24rem;
	}
	
	.release.alt .-header::after {
		left: 6.5rem;
	}
}


@media screen and (max-width: 600px) {
	html {
		overflow: auto;
	}

	.card-wide,
	.cards-alternating .card:nth-child(even) {
		flex-direction: column;
	}

	.card-wide-head {
		max-width: 100%;
	}

	.card-wide img {
		width: 66%;
	}
	
	.card .card-wide-head {
		padding: 2em 1em 0 1em;
	}

	.card-ss > div + div {
		margin: 0;
	}

	.ss-dp p,
	.ss-phone p {
		margin: 0;
		margin-top: 0.5rem;
	}

	.ss-phone {
		margin-top: 2rem;
		margin-left: 0;
	}

	.ss-phone img {
		max-height: 12rem;
		width: auto;
	}

	.ss-tp {
		margin-top: 2rem;
	}

	.quotes {
		width: 100%;
	}

	.quotes p {
		font-size: 0.9rem;
	}

	.gdpr-cookies {
		flex-direction: column;
	}

	.gdpr-cookies .cookie-controls {
		flex: 0;
		margin: 0;
		margin-top: 1rem;
	}
}


@media screen and (max-width: 500px) {
	.block-right,
	.block-right input,
	.block-right textarea {
		width: 100%;
	}

	.overlay-form img {
		opacity: 0.5;
	}

	p,
	a {
		font-size: 0.85rem !important;
	}

	.menu {
		width: 100%;
		justify-content: space-between;
	}

	.menu a {
		font-size: 1rem !important;
	}

	.menu a + a {
		margin: 0;
	}

	button {
		padding: 0 1rem;
	}

	main h1 {
		font-size: 1.25rem;
	}

	.card {
		flex: 1 1 auto;
	}

	.card h2 {
		font-size: 1.25rem !important;
	}

	.card:hover:not(.card-wide) {
		top: 0;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 2px 2px 5px rgba(0, 0, 0, 0.24);
	}

	.card-stretcher {
		flex: 1 1 0;
	}

	.close {
		width: 3rem;
		height: 3rem;
	}

	.close icon {
		font-size: 1.5rem;
	}

	.footer-content {
		flex-direction: column;
	}

	.footer-text {
		padding: 0;
		margin: 0;
		text-align: center;
	}

	.footer-text + .footer-text {
		border: none;
		border-top: 1px solid #555;
		padding-top: 1.5em;
		margin-top: 1.5em;
	}
	
}


@media print {
	header,
	footer {
		display: none;
	}

	main {
		margin: 0;
		padding: 0;
	}
}
