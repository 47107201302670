@charset "utf-8";

/* Global */

* {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    padding: 0;
    margin: 0;
    border: 0;
}

*::selection {
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
}

*::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
}

*::-webkit-scrollbar-track {
    background-color: #bbb;
    border-radius: 0;
}

*::-webkit-scrollbar-thumb {
    background-color: #777;
    border-radius: 0;
}

html {
    background-color: #ccc;
    margin-left: 3em;
    font-size: 20px;
    height: 100%;
}

body {
    height: 100%;
}

h1 {
    position: relative;
    font-weight: normal;
    letter-spacing: 0.05em;
    font-size: 1.75rem;
}

h2 {
    font-weight: normal;
    letter-spacing: 0.08em;
    font-size: 1.25rem;
    margin: 1em 0;
}

h2:first-child {
    margin: 0;
    margin-bottom: 1em;
}

h3 {
    display: table;
    margin: 1em 0;
    letter-spacing: 0.1em;
    color: #333;
    font-size: 1rem;
    font-weight: normal;
    border-bottom: 0.1em solid #666;
}

h2 + h3,
.block + h3 {
    margin-top: 0;
}

h4 {
    display: inline-block;
    margin: 1rem 0;
    letter-spacing: 0.15rem;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

p {
    font-size: 0.8rem;
    color: #333;
}

ol,
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a,
a:link,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}

.alert {
    color: #d30 !important;
}

/* Templates & Documents */

ps-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

ps-header div {
    flex: 1 1 50px;
    height: 64px;
    margin-left: 2em;
    text-align: right;
}

ps-header img {
    height: 100%;
    max-width: 200px;
    object-fit: contain;
}

ps-footer {
    display: none;
    position: fixed;
    width: 100%;
    bottom: 0;
    font-size: 0.8rem;
}

ps-footer > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* Main Layout */

.content {
    overflow: auto;
    height: 100%;
}

.main-box {
    position: relative;
    margin: 0.5rem auto;
    background-color: #fff;
    box-sizing: border-box;
    width: 55rem;
    box-shadow: 0 0 2rem -0.2rem rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.main-box + ui-view .main-box {
    margin-top: -0.5rem;
    z-index: 1;
}

.main-box + .main-box {
    margin-top: 1rem !important;
}

.main-box-header {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 6rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    background-color: #666;
    color: #fff;
}

.main-box-content {
    box-sizing: border-box;
    color: #333;
    padding: 2rem;
}

.active:hover {
    cursor: pointer;
}

table .icons-left .active:hover {
    color: #d30 !important;
}

ul .active:hover:not(:last-child) {
    color: #d30 !important;
}

.icon {
    position: relative;
    top: 0.1em;
    margin-right: 0.15em;
    font-size: inherit;
    color: inherit;
    user-select: none;
}

.icon-header,
.icon-dlg {
    position: absolute;
    top: -0.6rem;
    color: #fff;
    cursor: default;
    user-select: none;
    opacity: 0.25;
}

.icon-header {
    font-size: 8em;
    right: -0.2em;
}

.icon-dlg {
    left: -0.2em;
    font-size: 6em;
}

/* PS-Filters & Tooltips */

ps-filters {
    overflow: hidden;
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.25rem;
    background: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.3);
    border-right: 0.25rem solid #555;
    white-space: nowrap;
    user-select: none;
    box-shadow: none;
    transition: 0.15s 0.2s all ease-in-out;
    z-index: 8;
}

ps-filters:hover {
    background: #fff;
    color: #555;
    border-color: #488;
    box-shadow: 0 0 0.5rem -0.1rem rgba(0, 0, 0, 0.5);
}

ps-filters .block {
    width: fit-content;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap;
    align-items: center;
}

ps-filters .if {
    width: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
    color: #555;
    cursor: pointer;
    transition: 0.15s 0.2s all ease-in-out;
}

ps-filters:hover .if {
    width: 8rem;
    visibility: visible;
}

ps-filters .block > icon {
    margin: 0.25rem;
}

ps-filters .icon-input {
    display: none;
}

.info {
    display: inline-block;
    position: relative;
}

.info::before {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    max-width: 20rem;
    height: calc(100% + 0.2rem);
    border-bottom: 2px dashed #45c;
    opacity: 0;
    transition: 0.2s all ease-in-out;
}

.info::after {
    content: "?";
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: -0.9em;
    left: -0.6em;
    width: 1em;
    height: 1em;
    padding: 0.1rem;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background: #45c;
    border-radius: 50%;
    border: 2px solid #fff;
    transform: scale(0.5);
    opacity: 0;
    transition: 0.2s all ease-in-out;
    z-index: 120;
}

tooltip {
    position: absolute;
    top: 75%;
    left: 0;
    padding: 0.5rem;
    width: 100%;
    min-width: 10rem !important;
    max-width: 20rem;
    background: #fff;
    color: #333;
    border: 2px solid #45c;
    font-size: 0.8rem;
    letter-spacing: 0;
    box-sizing: border-box;
    user-select: none;
    z-index: 100;
    box-shadow: 0 0.2rem 2rem -0.25rem rgba(0, 0, 0, 0.3);
    transition: 0.2s all ease-in-out;
    opacity: 0;
    visibility: hidden;
}

ps-filters.help-active .info-switch{
    color: #d30;
}

ps-filters.help-active ~ * .info::before {
    width: 100%;
    opacity: 1;
}

ps-filters.help-active ~ * .info::after {
    transform: scale(1);
    opacity: 1;
}

ps-filters.help-active ~ * .info:hover tooltip {
    top: calc(100% + 0.2rem);
    opacity: 1;
    visibility: visible;
}

/* PS-Nav */

nav {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 3rem;
    height: 100%;
    background: #333;
    transition: 0.15s 0.2s ease-in-out;
    z-index: 9;
    box-shadow: 0.15rem 0 0.25rem 0 rgba(0, 0, 0, 0.3);
    user-select: none;
}

nav:hover {
    width: 12rem;
}

nav .nav-mobile {
    display: none;
}

.nav-mobile .nav-group > div {
    display: flex;
    flex-direction: column;
}

nav a {
    display: flex;
    height: 1.8rem;
    align-items: center;
    color: #ccc !important;
    transition: all 0.15s ease-in-out;
}

nav a:hover {
    color: #fff !important;
    background: #666;
}

nav a .icon-nav {
    flex: 0 0 3rem;
    text-align: center;
    font-size: 1rem;
}

nav a .text-nav {
    letter-spacing: 0.05rem;
    text-align: left;
    font-size: 0.8rem;
}

nav .separator {
    display: none;
    margin: 0.4rem auto;
    height: 2px;
    width: calc(100% - 1rem);
    background: #555;
    transition: all 0.15s 0.2s ease-in-out;
}

nav .nav-group > div + div .separator:not(:only-child) {
    display: block;
}

nav .settings {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* PS-Tabs */

.ps-tabs {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-left: 2em;
    color: #666;
}

.ps-tab {
    position: relative;
    padding: 1em 0.5em 0.5em 0.5em;
    letter-spacing: 0.05em;
    margin-right: 0.5em;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.2s ease-out;
}

.ps-tab:hover {
    background: #eee;
    color: #333;
}

.ps-tab .bar {
    position: absolute;
    left: 0;
    bottom: 0;
}

.ps-tab:hover .bar:before,
.ps-tab:hover .bar:after {
    width: 50%;
}

.ps-tab-active {
    background: #666;
    color: #fff !important;
}

.ps-tabs:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2rem;
    height: 2rem;
    width: 100%;
    background: #fff;
}

.card-header + .ps-tabs:after {
    display: none;
}

/* Tables */

table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.8rem;
}

tr {
    height: 2.5em;
    transition: all 0s;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

tr:hover {
    box-shadow: 0 0 200px 200px inset rgba(0, 0, 0, 0.05);
}

th {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: left;
    background-color: #666;
    color: #fff;
    padding: 0.2em 0.5em;
}

td {
    position: relative;
    padding: 0.2em 0.5em;
}

td tooltip:empty {
    display: none;
}

td:hover tooltip {
	top: 1rem;
	opacity: 1;
    visibility: visible;
}

tfoot tr {
    display: none;
    border: none !important;
}

table .icon {
    font-size: 1.1rem;
}

table .icon-td {
    position: relative;
    top: 0.2em;
    font-size: 1rem;
    line-height: 0.5em;
}

ps-table .controls {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-top: 1rem;
}

ps-table .controls > * {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
}

ps-custom-table .controls {
    display: none;
}

ps-table-filter {
    flex: 1 1 0;
    overflow: hidden;
    margin-right: 1em;
}

ps-table-filter > div {
    display: flex;
}

ps-table-pager {
    display: flex;
}

ps-table-pager > div {
    margin-left: 0.5em;
    position: relative;
}

.pages {
    display: flex;
    margin-left: 1em;
}

.pages div {
    margin: 0 0.5em;
}

.pages button {
    position: relative;
    top: -0.1em;
    width: 6rem;
    padding: 0.1em 0;
}

.print-box {
    margin-bottom: 1em;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.ascending:after {
    content: "\23F6";
    position: absolute;
    top: 0.5em;
}

.descending:after {
    content: "\23F7";
    position: absolute;
    top: 0.5em;
}

/* Input */

.block-flex {
    display: flex;
    width: 100%;
}

.block-flex > div {
    flex: 1 1 0;
    max-width: 50%;
}

.block-flex > div + div {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 1px solid #ccc;
}

.block-flex ps-select {
    max-width: 20rem;
}

.block-flex h3 {
    margin-top: 0;
}

.block {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
}

.block hr {
    width: 100%;
}

.block h3 {
    margin-right: auto;
}

ps-input {
    position: relative;
    text-align: left;
    font-size: inherit;
}

ps-select {
    display: block;
}

ps-range {
    display: inline-block;
}

ps-range > div {
    display: flex;
    width: 100%;
}

ps-range ps-moment-picker {
    flex: 1 1 45%;
}

ps-location {
    width: 100%;
}

ps-location div {
    display: flex;
    width: 100%;
}

.if-adresse {
    width: 45%;
}

.if-plz {
    width: 15%;
}

.if-ort {
    width: 33%;
}

.if {
    flex: 1 1 auto;
    margin-bottom: 1.5em;
}

.if-fix {
    flex: 0 0 auto;
}

.block p,
.block ul {
    flex: 1 1 100%;
    font-size: 0.9em;
    margin-bottom: 1.5em;
    text-align: left;
}

.if + .if {
    margin-left: 1em;
}

.if + ps-button {
    position: relative;
    top: -0.2em;
    height: 1.5em;
    margin-left: 1em;
}

.if + ps-button button {
    padding: 0 1em;
}

span.if + .if {
    margin-left: 0.5em;
}

.if-ph {
    display: none !important;
}

h1.if-ph{
	margin-bottom: 1rem;
}

.if div {
    display: flex;
    align-items: center;
    min-width: 0;
}

.icon-input {
    font-size: 1.1em;
    cursor: default;
    color: inherit;
}

.element {
    position: relative;
    width: 100%;
}

input {
    font-size: inherit;
    background: transparent;
    color: inherit;
    width: 100%;
    text-align: inherit;
    padding-bottom: 0.1em;
    padding-left: 0.2em;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}

input:focus {
    outline: none;
}

input:hover {
    border-bottom: 1px solid #888;
    outline: none;
}

.bar {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.bar:before,
.bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #3399ff;
    transition: 0.3s ease all;
    z-index: 1;
}

.bar:before {
    left: 50%;
}

.bar:after {
    right: 50%;
}

input:focus ~ .bar:before,
input:focus ~ .bar:after {
    width: 50%;
}

.element label {
    position: absolute;
    top: 0;
    left: 0.2em;
    color: inherit;
    opacity: 0.6;
    font-size: inherit;
    font-weight: normal;
    white-space: nowrap;
    pointer-events: none;
    transition: 0.2s all ease-in-out;
}

.input-active label,
input:focus ~ label,
select:focus ~ label {
    top: -1.2em;
    left: 0.3em;
    letter-spacing: 0.5px;
    font-size: 0.7em;
    color: #aaa;
    opacity: 1;
}

.label-invisible .input-active label,
.label-invisible.input-active label,
.label-invisible input:focus ~ label,
.label-invisible select:focus ~ label {
    top: 0.5em;
    font-size: inherit;
    letter-spacing: 0;
    color: transparent !important;
}

.input-error .icon-input {
    color: transparent;
    visibility: hidden;
}

.input-error label {
    color: #d30 !important;
}

.input-error .element:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1.1em;
    width: 100%;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 33.33%,
        rgba(255, 0, 0, 0.15) 33.33%,
        rgba(255, 0, 0, 0.15) 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 83.33%,
        rgba(255, 0, 0, 0.15) 83.33%,
        rgba(255, 0, 0, 0.15) 100%
    );
    background-size: 36px 36px;
    pointer-events: none;
}

.input-error .element:after {
    content: "!";
    position: absolute;
    top: -0.1em;
    left: -0.7em;
    font-weight: bold;
    color: #d30;
    text-align: center;
    font-size: 1.2em;
}

input::placeholder {
    color: transparent;
}

textarea {
    width: 100%;
    min-height: 3rem;
    max-height: 20rem;
    background: #eee;
    font-size: 0.8rem;
    box-sizing: border-box;
    padding: 0.25rem;
    resize: none;
    outline: 2px solid transparent;
    outline-offset: -2px;
    transition: 0.2s all linear;
}

textarea:focus {
    outline: 2px solid #666;
}

.details {
    background: #eee;
    box-sizing: border-box;
    margin-bottom: 1.5em;
    padding: 0.5em;
    font-size: 0.8em;
    border-left: 3px solid;
}

.details p {
    margin: 0;
}

/* Select */

select {
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    -moz-appearance: none;
    cursor: pointer;
}

select:invalid {
    color: #aaa;
}

select:hover {
    border-bottom: 1px solid #888;
    outline: none;
}

select:focus {
    outline: none;
}

select:focus ~ .bar:before {
    width: 50%;
}

select:focus ~ .bar:after {
    width: 50%;
}

option {
    color: #555;
}

ps-template-select {
    display: flex;
    width: 100%;
}

/* Switch */

ps-slider {
    flex: 0 0 auto !important;
    font-size: inherit;
    position: relative;
    display: flex;
    margin-right: 2.5em;
    padding-bottom: 0.2em;
}

.switch-input {
    display: none;
}

.switch-label {
    cursor: pointer;
    background: #333;
}

.switch-label:before,
.switch-label:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: calc(50% - 0.1em);
    margin-left: 0.2em;
    transform: translate(0, -50%);
    transition: all 0.2s ease;
}

.switch-label:before {
    width: 2em;
    height: 0.8em;
    border-radius: 0.4em;
}

.switch-label:after {
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098),
        0 1px 5px 0 rgba(0, 0, 0, 0.084);
}

.switch-input:not(:checked) + .switch-label:before {
    background: #ccc;
}

.switch-input:not(:checked) + .switch-label:after {
    background: #f9f9f9;
}

.switch-input:checked + .switch-label:before {
    background: inherit;
    opacity: 0.5;
}

.switch-input:checked + .switch-label:after {
    background: inherit;
    transform: translate(80%, -50%);
}

/* Charts */

ps-chart {
    position: relative;
    display: block;
    width: 100%;
    height: 10rem;
    padding-top: 1rem;
}

ps-chart .legend {
    position: absolute;
    display: flex;
    align-items: center;
    top: -0.5rem;
    right: 0;
    margin-left: auto;
}

ps-chart .legend div {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
}

ps-chart .bars {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

ps-chart .data-set {
    flex: 1 1 0;
    display: flex;
    align-items: flex-end;
    height: 100%;
}

ps-chart .data-bar {
    width: 100%;
    margin: 0 1px;
    background: #666;
}

ps-chart .data-info {
    position: absolute;
    top: -1.5rem;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all ease-in-out;
    white-space: nowrap;
}

ps-chart .data-bar:hover {
    box-shadow: inset 0 0 20rem 20rem rgba(255, 255, 255, 0.25);
}

ps-chart .data-bar:hover ~ .data-info {
    opacity: 1;
    visibility: visible;
}

ps-chart .x-axis {
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: 2rem;
    width: 100%;
    top: auto;
    bottom: -2rem;
    pointer-events: none;
    user-select: none;
}

ps-chart .x-axis > div {
    flex: 1 1 0;
    font-size: 0.8rem;
    padding: 0.2rem 0;
    text-align: center;
}

ps-chart .y-axis {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

ps-chart .y-axis > div {
    width: fit-content;
    background: #fff;
    font-size: 0.8rem;
    padding: 0.2rem;
    margin: 0.2rem;
    box-shadow: 0 0.1rem 1rem -0.1rem rgba(0, 0, 0, 0.3);
    opacity: 0.8;
}

/* Angucomplete */

.angucomplete-holder {
    position: relative;
}

.angucomplete-dropdown {
    overflow-y: scroll;
    overflow-x: auto;
    position: fixed;
    cursor: default;
    min-width: 12rem;
    max-height: 10rem;
    font-size: 0.9rem;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.15);
}

.angucomplete-searching {
    color: #ccc;
    font-size: inherit;
}

.angucomplete-description {
    font-size: inherit;
}

.angucomplete-row {
    padding: 0.3rem;
    color: #333;
    clear: both;
}

.angucomplete-selected-row {
    background-color: #333;
    color: #fff;
}

/* Picture Uploader */

.pic-block {
    display: flex;
    width: 100%;
    padding-top: 0.5em;
}

.pic-block .block {
    flex: 1 1 auto;
    padding-top: 0.5em;
}

.pic-upload {
    flex: 0 0 auto;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: top;
    width: 10em;
    height: 10em;
    margin-right: 1.5em;
    background: #888;
    border: 1px solid #333;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
    transition: all 0.2s;
}

.pic-upload:hover {
    background: #444;
    cursor: pointer;
}

.pic-upload ng-transclude {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
}

.pic-upload img {
    max-width: 10em;
    max-height: 10em;
    transition: all 0.2s;
}

.pic-upload:hover img {
    opacity: 0.2;
}

.pic-upload .pic-text-top,
.pic-upload .pic-text-bot {
    letter-spacing: 1px;
    position: absolute;
    width: 100%;
    font-size: 1.2em;
    color: #fff;
    top: -30%;
    left: 0;
    transition: all 0.2s ease-in-out;
}

.pic-upload .pic-text-bot {
    top: 100%;
}

.pic-upload:hover .pic-text-top {
    top: 25%;
}

.pic-upload:hover .pic-text-bot {
    top: 50%;
}

.pic-upload .pic-text-top .icon-profile {
    font-size: 2.5rem;
}

.pic-upload icon {
    top: 0;
    margin: auto;
    font-size: 10em;
    cursor: pointer;
}

/* Moment Picker */

ps-moment-picker {
    text-align: left;
}

.moment-picker-input {
    cursor: pointer;
}

.moment-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    font-size: 0.8rem;
    transform-origin: top;
    animation: mp 0.2s ease-in-out;
}

@keyframes mp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.moment-picker .moment-picker-container {
    position: absolute;
    left: 0;
    margin-top: 0;
    color: #333;
    min-width: 18em;
    box-shadow: 0 0.2rem 2rem -0.25rem rgba(0, 0, 0, 0.3);
}

.moment-picker.top .moment-picker-container {
    bottom: 100%;
    margin-top: auto;
    margin-bottom: 1em;
}

.moment-picker.right .moment-picker-container {
    right: 100%;
    margin-left: auto;
    margin-right: -1em;
}

.moment-picker table {
    width: 100%;
    font-size: inherit;
}

.moment-picker .header-view th {
    background: #333;
}

.moment-picker .header-view th:nth-child(2) {
    width: 10em;
}

.moment-picker .header-view th:hover {
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.1);
}

.moment-picker tr {
    padding: 0;
    height: 2.5em;
    background: #fff;
    border: none;
}

.moment-picker tr:hover {
    box-shadow: none;
}

.moment-picker td,
.moment-picker th {
    padding: 0;
    text-align: center;
    cursor: default;
}

.moment-picker td.today {
    background-color: #999;
    color: #fff;
    font-weight: bold;
}

.moment-picker td.selected {
    color: #fff;
    border-color: #333;
    background-color: #333;
}

.moment-picker td:hover {
    background-color: #555;
    color: #fff;
}

.moment-picker td.disabled,
.moment-picker td.disabled:hover {
    color: #bbb;
    background: #fff;
    cursor: default;
}

.moment-picker th.disabled,
.moment-picker th.disabled:hover {
    color: #666;
    background: #333;
    cursor: default;
}

.moment-picker .month-view .moment-picker-specific-views th {
    background: #555;
    cursor: default;
}

.moment-picker .moment-picker-specific-views table {
    border-collapse: collapse;
    border-spacing: 0;
}

.if-mp-day .moment-picker .moment-picker-specific-views th {
    background: none;
    cursor: default;
}

.week-picker.moment-picker tr:hover {
    background: #555;
    color: #fff;
}

.week-picker.moment-picker td.selected ~ td,
.week-picker.moment-picker td.today ~ td,
.week-picker.moment-picker td.selected {
    background-color: #333;
    color: #fff;
    font-weight: bold;
}

.week-picker.moment-picker td.disabled,
.week-picker.moment-picker th.disabled {
    background: inherit;
}

.week-picker.moment-picker td.disabled:hover,
.week-picker.moment-picker th.disabled:hover {
    background: inherit;
}

ps-moment-picker .reset:hover {
    cursor: pointer;
    color: #d30;
}

ps-moment-picker .mp-overlay {
    display: none;
}

/* NG-Map */

ng-map .gm-svpc {
    display: none;
}

ps-marker {
    position: absolute;
}

.custom-marker {
    background: transparent;
    width: 1px;
    height: 1px;
    cursor: pointer;
}

.custom-marker:hover {
    z-index: 10 !important;
}

.custom-marker.selected {
    z-index: 9;
}

.custom-marker:hover,
.custom-marker.selected {
    background: inherit;
}

.custom-marker > * {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
}

.custom-marker icon {
    font-size: 1.5rem;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0.1rem 1rem -0.1rem rgba(0, 0, 0, 0.3);
}

.custom-marker:hover icon,
.custom-marker.selected icon {
    border-radius: 0;
    color: #fff;
    background: inherit;
}

.custom-marker label {
    min-width: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    background: #fff;
    padding: 0.25rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.1rem 1rem -0.1rem rgba(0, 0, 0, 0.3);
}

.custom-marker .marker-info {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    height: 1.5rem;
    width: fit-content;
    box-sizing: border-box;
    transform: scaleX(0);
    transform-origin: left;
    padding: 0 0.5rem;
    background: #fff;
    font-size: 0.8rem;
    border: 1px solid;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all ease-in-out;
    box-shadow: 0 0.2rem 2rem -0.25rem rgba(0, 0, 0, 0.3);
}

.custom-marker:hover .marker-info,
.custom-marker.selected .marker-info {
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
}

.marker-details {
    margin-top: 1.5rem;
    height: fit-content;
    width: fit-content;
    background: #fff;
    text-align: left;
    padding: 0.5rem;
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.2s all ease-in-out;
}

.marker-details p {
    font-size: 0.7rem !important;
    line-height: 1.5;
    margin: 0;
}

.custom-marker:hover .marker-details {
    transition: 0.2s 0.5s all ease-in-out;
    opacity: 0.85;
    visibility: visible;
    transform: scaleY(1);
}

/* Buttons */

ps-button,
ps-print-button {
    display: inline-block;
    height: 2rem;
}

button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 0.9rem;
    height: 100%;
    padding: 0 2rem;
    border: 1px solid #666;
    background: transparent;
    color: #333;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.2s ease;
}

button:hover {
    background: #666;
    border-color: #666;
    color: #fff !important;
}

button:focus {
    outline: 0;
}

button:active {
    top: 1px;
}

button:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4%;
    height: 3px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 0.4;
    }
    100% {
        opacity: 0;
        transform: scale(50, 50);
    }
}

button:focus:not(:active)::after {
    animation: ripple 0.6s ease-in-out;
}

.buttons {
    display: flex;
    justify-content: space-around;
}

.buttons ps-button {
    height: 1.75rem;
}

.buttons button {
    min-width: 8.5rem;
    padding: 0;
}

.buttons > * + * {
    margin-left: 1em;
}

.buttons-flex {
    display: flex;
    justify-content: space-between;
}

.buttons-flex ps-button,
.buttons-flex ps-print-button {
    margin-top: 2em;
}

.buttons-flex div:first-child button:first-child {
    margin-right: 2em;
}

.block + .buttons-flex ps-button,
.block + .buttons-flex ps-print-button,
ps-table + ps-button {
    margin-top: 1rem;
}

ps-button .icon,
ps-print-button .icon,
button .icon {
    top: 0;
    left: 0;
    padding: 0;
    cursor: pointer;
}

ps-template-select button {
    min-width: 8em;
    padding: 0 !important;
}

/* Dialogs */

.overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: -10%;
    left: -10%;
    height: 120%;
    width: 120%;
    background-color: rgba(0, 0, 0, 0.5);
    user-select: none;
    z-index: 8;
    transition: all 0.2s;
}

.overlay.ng-enter {
    visibility: hidden;
    opacity: 0;
    transform: scale(1.1);
}

.overlay.ng-enter.ng-enter-active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.overlay.ng-leave {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.overlay.ng-leave.ng-leave-active {
    visibility: hidden;
    opacity: 0;
    transform: scale(1.1);
}

.dialog {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 4em);
    max-width: 30rem;
    margin-left: 3rem;
    background: #fff;
    z-index: 9;
    box-shadow: 0 0.2rem 3rem -0.3rem rgba(0, 0, 0, 0.5);
}

.dialog-header {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 4em;
    text-align: center;
    background-color: #666;
    box-sizing: border-box;
    text-transform: uppercase;
    padding: 1em;
    color: #fff;
}

.dialog-content {
    box-sizing: border-box;
    padding: 2em;
    font-size: 0.9em;
}

.dialog-content .block {
    margin-bottom: 0.5em;
}

.dialog-content .block span {
    width: 100%;
    font-size: 0.9rem;
    text-align: center;
}

/* Dataforms */

.overlay-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: -50%;
    left: -50%;
    height: 200%;
    width: 200%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0.25rem) saturate(0.25);
    user-select: none;
    z-index: 8;
    transition: all 0.3s;
}

.overlay-form.ng-enter {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
}

.overlay-form.ng-enter.ng-enter-active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.overlay-form.ng-leave {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.overlay-form.ng-leave.ng-leave-active {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
}

.dataform {
    max-height: calc(100vh - 4em);
    overflow: auto;
    width: 40rem;
    margin-left: 3rem;
    background: #fff;
    z-index: 9;
    box-shadow: 0 0.2rem 3rem -0.3rem rgba(0, 0, 0, 0.5);
}

.dataform-header {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 4em;
    background-color: #666;
    box-sizing: border-box;
    font-size: 1.1em;
    text-transform: uppercase;
    text-align: center;
    padding: 1em;
    color: #fff;
}

.dataform-content {
    padding: 2em;
}

.dataform-content ul {
    font-size: 0.8em;
}

.dataform .details {
    width: 100%;
}

.dataform ng-map {
    height: 100%;
    width: 300px;
    margin-right: 2em;
}

/* Progress Bar */

.overlay-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    user-select: none;
    z-index: 12;
    transition: all 0.3s;
}

.overlay-bar.ng-enter {
    visibility: hidden;
    opacity: 0;
    transform: scale(1.1);
}

.overlay-bar.ng-enter.ng-enter-active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.overlay-bar.ng-leave {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.overlay-bar.ng-leave.ng-leave-active {
    visibility: hidden;
    opacity: 0;
    transform: scale(1.1);
}

.progress-modal {
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 30rem;
    max-width: 40rem;
    margin-left: 3rem;
    background-color: #fff;
    z-index: 13;
    box-shadow: 0 0.2rem 3rem -0.3rem rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
}

.progress-modal .icon-dlg {
    font-size: 4.5rem;
    left: auto;
    right: -1.25rem;
}

.progress-header {
    position: relative;
    overflow: hidden;
    height: 3em;
    background-color: #666;
    box-sizing: border-box;
    text-align: center;
    text-transform: uppercase;
    padding: 1em;
    color: #fff;
}

.progress-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem 1.5rem;
}

.progress-content > div:nth-child(2) {
    margin-left: auto;
    padding-left: 1rem;
}

.progress-bar {
    display: flex;
    height: 3px;
    width: calc(100% - 3rem);
    margin: 0 1.5rem 1.5rem 1.5rem;
    background-color: #ccc;
    box-sizing: border-box;
}

.progress-bar:before {
    height: 3px;
    width: 100%;
    box-sizing: border-box;
    background-color: #333;
    content: "";
    animation: progress 2s linear infinite;
}

@keyframes progress {
    0% {
        margin-left: 0%;
        margin-right: 100%;
    }
    40% {
        margin-left: 40%;
        margin-right: 0%;
    }
    100% {
        margin-left: 100%;
        margin-right: 0%;
    }
}

/* Snackbar */

.snackbar {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    max-width: 25rem;
    margin: 0 auto;
    padding: 0.5em 1em;
    background: #333;
    color: #fff;
    font-size: 0.9rem;
    box-shadow: 0 -0.1rem 1rem -0.1rem rgba(0, 0, 0, 0.3);
    animation: snackbar-slide 0.3s ease-in-out forwards;
    z-index: 15;
}

@keyframes snackbar-slide {
    0% {
        bottom: -2rem;
        opacity: 0;
    }
    70% {
        bottom: 0.5rem;
        opacity: 0.7;
    }
    90% {
        bottom: -0.2rem;
        opacity: 0.9;
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
}

/* List Cards */

.list-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.list-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 2.5rem;
    min-width: 5rem;
    max-width: 11rem;
    padding: 0.4rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    text-align: left;
    color: #666;
    background: #f7f7f7;
    border-top: 0.25rem solid #999;
    cursor: default;
    user-select: none;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 3px rgba(0, 0, 0, 0.24),
        inset 0 0 0 200px rgba(0, 0, 0, 0);
    transition: all 0.2s ease-in-out;
}

.list-card:hover:not(.list-card-selected) {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 3px rgba(0, 0, 0, 0.24),
        inset 0 0 0 200px rgba(0, 0, 0, 0.15);
    color: #333;
}

.list-card-selected {
    border-color: #666;
    color: #fff;
    background: #666;
}

.list-card-title {
    font-size: 0.9rem;
    font-weight: bold;
}

.list-card-description {
    font-size: 0.8rem;
}

.list-card-button {
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #666;
    color: #ccc;
    width: 100%;
    height: 0;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 15;
}

.list-card:hover .list-card-button {
    top: -1.5rem;
    height: 1.25rem;
    box-sizing: border-box;
    opacity: 1;
    visibility: visible;
}

.list-card-button span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.list-card-button span:hover {
    background: #d30;
    color: #fff;
}

.list-card-button .icon {
    top: 0;
    margin: 0;
    cursor: pointer;
}

.list-cards-vertical {
    flex-direction: column;
}

.list-cards-vertical .list-card {
    align-self: flex-start;
    border: 0;
    border-left: 0.25rem solid #999;
}

.list-cards-vertical .list-card-button {
    width: 0%;
    height: 100%;
}

.list-cards-vertical .list-card:hover .list-card-button {
    top: 0;
    left: -1.75rem;
    width: 1.5rem;
    height: 100%;
}

.list-cards-vertical .list-card-button span {
    flex-direction: column;
    font-size: 1.25rem;
}

/* Badges & SGBs */

ps-badge {
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1em;
    width: fit-content;
    font-size: 0.7rem;
    background: #666;
}

ps-badge:empty {
    display: none;
}

ps-badge + ps-badge {
    margin-left: 0.25em;
}

.if ~ ps-badge {
    margin-bottom: auto;
    margin-left: 0.5em;
}

.skill-code {
    padding: 0.1em 0.2em;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0.3em;
}

.shift-code {
    position: relative;
    width: 1.8rem;
    height: 1.2rem;
    text-transform: uppercase;
}

.contract-code {
    padding: 0.1em 0.2em;
    text-transform: uppercase;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 0.3em;
    background: transparent;
}

.mono1 {
    background: #eee;
    color: #000;
}

.mono2 {
    background: #bbb;
    color: #000;
}

.mono3 {
    background: #505050;
    color: #fff !important;
}

.mono4 {
    background: #000;
    color: #fff !important;
}

.red1 {
    background: #801010;
    color: #fff !important;
}

.red2 {
    background: #b04040;
    color: #fff !important;
}

.red3 {
    background: #f0a0a0;
    color: #000;
}

.yellow1 {
    background: #806000;
    color: #fff !important;
}

.yellow2 {
    background: #a09000;
    color: #fff !important;
}

.yellow3 {
    background: #e0b000;
    color: #000;
}

.green1 {
    background: #006000;
    color: #fff !important;
}

.green2 {
    background: #30a030;
    color: #fff !important;
}

.green3 {
    background: #60d060;
    color: #000;
}

.blue1 {
    background: #102080;
    color: #fff !important;
}

.blue2 {
    background: #5060b0;
    color: #fff !important;
}

.blue3 {
    background: #90b0f0;
    color: #000;
}

.pink {
    background: #d04080;
    color: #fff !important;
}

.violet {
    background: #7020a0;
    color: #fff !important;
}

.aqua {
    background: #20b0b0;
    color: #fff !important;
}

.orange {
    background: #e08000;
    color: #fff !important;
}

.sgb {
    display: inline-block;
    margin: 0.1em;
    padding: 0 0.2em;
    font-size: 0.8rem;
}

.sgb-xi {
    border: 1px solid #333;
    border-radius: 3px;
}

.sgb-v {
    border: 1px solid #095;
    border-radius: 3px;
    background: #095;
    color: #fff;
}

.sgb-45 {
    border: 1px solid #555;
    border-radius: 3px;
    background: #555;
    color: #fff;
}

.sgb-37 {
    border: 1px dotted #333;
}

.sgb-39 {
    border: 1px dashed #333;
}

.sgb-p {
    border: 1px solid #999;
    border-radius: 3px;
    background: #ddd;
}

/* GREEN color theme */

.green h2,
.green h4,
.green input:focus ~ label,
.green .input-active label,
.green select:focus ~ label,
.green .active:hover,
.green button,
.green.custom-marker {
    color: #095;
}

.green h3,
.green .list-card:hover,
.green button {
    border-color: #095;
}

nav a.green:hover,
.green [class*="-header"],
.green .ps-tab-active,
.green .tabs [type="radio"]:checked ~ label .label-tab,
.green .bar:before,
.green .bar:after,
.green .switch-label,
.green .progress-bar:before,
.green .moment-picker .header-view th,
.green.snackbar,
.green .list-card-selected,
.green button:hover,
.green.custom-marker {
    background-color: #095;
}

/* TEAL color theme */

.teal h2,
.teal h4,
.teal input:focus ~ label,
.teal .input-active label,
.teal select:focus ~ label,
.teal .active:hover,
.teal button,
.teal.custom-marker {
    color: #079;
}

.teal h3,
.teal .list-card:hover,
.teal button {
    border-color: #079;
}

nav a.teal:hover,
.teal [class*="-header"],
.teal .ps-tab-active,
.teal .tabs [type="radio"]:checked ~ label .label-tab,
.teal .bar:before,
.teal .bar:after,
.teal .switch-label,
.teal .progress-bar:before,
.teal .moment-picker .header-view th,
.teal.snackbar,
.teal .list-card-selected,
.teal button:hover,
.teal.custom-marker {
    background-color: #079;
}

/* PURPLE color theme */

.purple h2,
.purple h4,
.purple input:focus ~ label,
.purple .input-active label,
.purple select:focus ~ label,
.purple .active:hover,
.purple button,
.purple.custom-marker {
    color: #80a;
}

.purple h3,
.purple .list-card:hover,
.purple button {
    border-color: #80a;
}

nav a.purple:hover,
.purple [class*="-header"],
.purple .ps-tab-active,
.purple .tabs [type="radio"]:checked ~ label .label-tab,
.purple .bar:before,
.purple .bar:after,
.purple .switch-label,
.purple .progress-bar:before,
.purple .moment-picker .header-view th,
.purple.snackbar,
.purple .list-card-selected,
.purple button:hover,
.purple.custom-marker {
    background-color: #80a;
}

/* GREY color theme */

.grey h2,
.grey h4,
.grey input:focus ~ label,
.grey .input-active label,
.grey select:focus ~ label,
.grey .active:hover,
.grey button,
.grey.custom-marker {
    color: #488;
}

.grey h3,
.grey .list-card:hover,
.grey button {
    border-color: #488;
}

nav a.grey:hover,
.grey [class*="-header"],
.grey .ps-tab-active,
.grey .tabs [type="radio"]:checked ~ label .label-tab,
.grey .bar:before,
.grey .bar:after,
.grey .switch-label,
.grey .progress-bar:before,
.grey .moment-picker .header-view th,
.grey.snackbar,
.grey .list-card-selected,
.grey button:hover,
.grey.custom-marker {
    background-color: #488;
}

/* GOLD color theme */

.gold h2,
.gold h4,
.gold input:focus ~ label,
.gold .input-active label,
.gold select:focus ~ label,
.gold .active:hover,
.gold button,
.gold.custom-marker {
    color: #d80;
}

.gold h3,
.gold .list-card:hover,
.gold button {
    border-color: #d80;
}

nav a.gold:hover,
.gold [class*="-header"],
.gold .ps-tab-active,
.gold .tabs [type="radio"]:checked ~ label .label-tab,
.gold .bar:before,
.gold .bar:after,
.gold .switch-label,
.gold .progress-bar:before,
.gold .moment-picker .header-view th,
.gold.snackbar,
.gold .list-card-selected,
.gold button:hover,
.gold.custom-marker {
    background-color: #d80;
}

/* BLUE color theme */

.blue h2,
.blue h4,
.blue input:focus ~ label,
.blue .input-active label,
.blue select:focus ~ label,
.blue .active:hover,
.blue button,
.blue.custom-marker {
    color: #45c;
}

.blue h3,
.blue .list-card:hover,
.blue button {
    border-color: #45c;
}

nav a.blue:hover,
.blue [class*="-header"],
.blue .ps-tab-active,
.blue .tabs [type="radio"]:checked ~ label .label-tab,
.blue .bar:before,
.blue .bar:after,
.blue .switch-label,
.blue .progress-bar:before,
.blue .moment-picker .header-view th,
.blue.snackbar,
.blue .list-card-selected,
.blue button:hover,
.blue.custom-marker {
    background-color: #45c;
}

/* RED color theme */

.red h2,
.red h4,
.red input:focus ~ label,
.red .input-active label,
.red select:focus ~ label,
.red .active:hover,
.red button,
.red.custom-marker {
    color: #d30;
}

.red h3,
.red .list-card:hover,
.red button {
    border-color: #d30;
}

nav a.red:hover,
.red [class*="-header"],
.red .ps-tab-active,
.red .tabs [type="radio"]:checked ~ label .label-tab,
.red .bar:before,
.red .bar:after,
.red .switch-label,
.red .progress-bar:before,
.red .moment-picker .header-view th,
.red.snackbar,
.red .list-card-selected,
.red button:hover,
.red.custom-marker {
    background-color: #d30;
}

/* DEFAULT color theme */

.default h2,
.default h4,
.default input:focus ~ label,
.default .input-active label,
.default select:focus ~ label,
.default .active:hover,
.default button,
.default.custom-marker {
    color: #333;
}

.default h3,
.default .list-card:hover,
.default button {
    border-color: #333;
}

nav a.default:hover,
.default [class*="-header"],
.default .ps-tab-active,
.default .tabs [type="radio"]:checked ~ label .label-tab,
.default .switch-label,
.default .progress-bar:before,
.default .moment-picker .header-view th,
.default.snackbar,
.default .list-card-selected,
.default button:hover,
.default.custom-marker {
    background-color: #333;
}

.default .bar:before,
.default .bar:after {
    background: #39f;
}

/* Responsive */

@media screen and (min-width: 450px) and (max-width: 1160px) {
    html {
        font-size: 1em;
    }

    .main-box {
        width: calc(100vw - 4rem);
        max-width: 50rem;
    }

    .ps-tab {
        letter-spacing: 0;
        margin: 0;
    }
}

@media screen and (min-width: 450px) and (max-width: 750px) {
    html {
        font-size: 15px;
    }

    .ps-tab {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 auto;
        text-align: center;
    }

    ps-table-pager {
        display: none;
    }

    .block {
        margin-left: -1rem;
    }

    .block > * {
        margin-left: 1rem;
    }

    .pic-block {
        flex-direction: column;
    }

    .pic-block .block {
        margin-top: 1em;
    }

    .dialog,
    .dataform,
    .progress-modal {
        width: calc(100vw - 5em) !important;
    }
}

@media screen and (max-width: 650px) {
    .block-flex {
        flex-direction: column;
    }

    .block-flex div + div {
        margin: 0;
        margin-top: 1rem;
        padding: 0;
        border: none;
    }
}

@media screen and (max-width: 450px) {
    html {
        height: calc(100% - 45px);
        margin: 0;
        margin-top: 45px;
        font-size: 14px;
        user-select: none;
    }

    h1 {
        font-size: 1.5rem;
        letter-spacing: 0.5px;
    }

    h2 {
        letter-spacing: 0.5px;
    }

    h3 {
        letter-spacing: 0.5px;
    }

    .content {
        overflow: visible;
        height: auto;
        width: 100vw;
    }

    .main-box {
        width: 100%;
        box-shadow: 0 0 2rem -0.2rem rgba(0, 0, 0, 0.5);
    }

    .main-box:first-of-type {
        margin-top: 0;
    }

    .main-box:last-of-type {
        margin-bottom: 0;
    }

    .main-box + ui-view .main-box {
        margin-top: 0;
    }

    .main-box-content {
        padding: 1em;
    }

    .main-box-header {
        height: auto;
        padding: 1em;
    }

    .icon-header,
    .icon-dlg {
        display: none;
    }

    ps-filters {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        top: 0.3rem;
        left: 4rem;
        height: 2rem;
        right: auto;
        border: none;
        background: transparent;
        color: #ccc;
        transition: 0.15s all ease-in-out;
        z-index: 9;
    }
    
    ps-filters:hover {
        height: 7rem;
        overflow: visible;
        flex-direction: column;
        background: #555;
        color: #ccc;
        box-shadow: 0 0 0.5rem -0.1rem rgba(0, 0, 0, 0.5);
    }
    
    ps-filters .if {
        color: #ccc;
        transition: 0.15s all ease-in-out;
    }
    
    ps-filters:hover .if {
        width: 11rem;
        margin-right: 0.5rem;
        visibility: visible;
    }

    nav {
        height: auto;
        width: 100vw !important;
        box-shadow: 0 3px 5px 0 rgba(2, 2, 2, 0.3);
    }

    nav .nav-group a {
        height: 0;
        opacity: 0;
        transition: 0.2s all ease-in-out;
    }

    nav.open .nav-group a {
        height: 3.5rem;
        opacity: 1;
    }

    nav .separator {
        margin: 0 auto;
        opacity: 0;
        transition: 0.2s all ease-in-out;
    }

    nav.open .separator {
        opacity: 1;
        margin: 1rem auto;
    }

    nav .nav-mobile {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 45px;
    }

    nav .icon-nav-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        font-size: 35px;
        line-height: 45px;
        text-align: center;
        color: #ccc;
        transition: 0.2s all ease-in-out;
    }

    nav .nav-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;
        height: 0;
        transition: 0.2s all ease-in-out;
    }

    nav.open .nav-main {
        opacity: 1;
        height: 100%;
    }

    nav .settings {
        position: initial;
        margin: 0;
    }

    nav.open .settings {
        margin-top: 5rem;
    }

    nav .icon-nav-mobile:nth-of-type(2),
    nav.open .icon-nav-mobile:nth-of-type(1) {
        opacity: 0;
        transform: scaleY(0);
    }

    nav.open .icon-nav-mobile:nth-of-type(2),
    nav .icon-nav-mobile:nth-of-type(1) {
        opacity: 1;
        transform: scaleY(1);
    }

    nav.open .nav-mobile::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    nav img {
        height: 25px;
        margin-right: 10px;
        transition: 0.2s all ease-in-out;
    }

    nav .nav-group {
        margin-top: 1rem;
    }

    nav .nav-group > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    nav .settings > div {
        border: none;
        margin-bottom: 1em;
    }

    nav a {
        flex: 1 1 0;
        flex-direction: column;
        font-size: 11px;
        height: auto;
        width: auto;
        max-width: 33%;
    }

    nav a .icon-nav {
        flex: 0 0 auto;
        font-size: 30px;
        margin-bottom: 0.2em;
    }

    nav a .text-nav {
        letter-spacing: 0.5px;
        text-align: center;
    }

    .ps-tabs {
        flex-wrap: wrap;
        justify-content: space-around;
        padding-left: 0;
        margin-bottom: 1em;
    }

    .ps-tab {
        flex: 1 1 0;
        padding: 0.5em;
        letter-spacing: 0;
        margin-right: 0;
        font-size: 16px;
        text-align: center;
    }

    ps-table {
        width: 100%;
    }

    table {
        table-layout: auto;
        width: 100%;
        font-size: 12px;
    }

    th,
    td {
        padding: 0.25em;
    }

    .controls {
        margin-top: 0.5em;
        font-size: 15px;
    }

    ps-table-filter {
        margin: 0;
    }

    ps-table-pager {
        display: none;
    }

    .sgb {
        font-size: 12px;
    }

    .pic-block {
        flex-direction: column;
    }

    .pic-upload {
        font-size: 1.5em;
        margin: 0;
        margin-bottom: 1rem;
    }

    .block {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }

    .block-flex > div {
        max-width: 100%;
    }

    .if {
        flex: 1 1 100%;
        margin-left: 0;
    }

    .if-plz,
    .if-ort,
    .if-m50 {
        flex: 1 1 45%;
    }

    .if + .if,
    .if + ps-button,
    span.if + .if,
    .if + *,
    ps-slider.if + .if,
    ps-slider.if + * {
        margin-left: 0;
    }

    span.if.if-fix {
        flex: 0 0 auto;
        width: auto;
    }

    ps-location > div {
        display: flex;
        flex-wrap: wrap;
    }

    textarea {
        font-size: 1rem;
    }

    .moment-picker {
        font-size: 1.1rem;
    }

    .moment-picker .moment-picker-container,
    .moment-picker.top .moment-picker-container,
    .moment-picker.right .moment-picker-container {
        position: fixed;
        top: 0;
        left: 50%;
        margin-top: 0;
        margin-left: -50%;
        width: 100%;
        z-index: 300;
        box-shadow: none;
        animation: mpc 0.3s ease-in-out;
    }

    @keyframes mpc {
        0% {
            top: -100%;
            opacity: 0;
        }
        100% {
            top: 0;
            opacity: 1;
        }
    }

    ps-moment-picker .mp-active + .mp-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
    }

    .moment-picker tbody {
        outline: 2px solid #555;
        outline-offset: -2px;
    }

    ps-button,
    ps-print-button {
        width: 100%;
        font-size: 1.1rem;
        height: 2.5rem !important;
    }

    ps-print-button {
        max-width: calc(100vw - 2rem);
    }

    button {
        min-width: auto !important;
        width: 100%;
        white-space: nowrap;
        padding: 0;
    }

    *:not(.outline) > button {
        color: #fff !important;
        border: none;
    }

    .green:not(.outline) > button,
    .green *:not(.outline) > button {
        background: #095;
    }

    .teal:not(.outline) > button,
    .teal *:not(.outline) > button {
        background: #079;
    }
    
    .purple:not(.outline) > button,
    .purple *:not(.outline) > button {
        background: #80a;
    }
    
    .grey:not(.outline) > button,
    .grey *:not(.outline) > button{
        background: #488;
    }
    
    .gold:not(.outline) > button,
    .gold *:not(.outline) > button {
        background: #d80;
    }
    
    .blue:not(.outline) > button,
    .blue *:not(.outline) > button {
        background: #45c;
    }
    
    .red:not(.outline) > button,
    .red *:not(.outline) > button {
        background: #d30;
    }

    .default:not(.outline) > button,
    .default *:not(.outline) > button {
        background: #333;
    }

    ps-template-select {
        flex-wrap: wrap;
    }

    ps-template-select ps-button {
        flex: 1 1 0 !important;
    }

    ps-template-select button {
        padding: 0 !important;
    }

    .buttons {
        width: 100%;
        justify-content: space-between;
        margin: 0;
    }

    .buttons > * + *,
    ps-template-select ps-button + ps-button {
        margin-left: 1px !important;
    }

    .buttons > * {
        flex: 1 1 0;
    }

    .buttons ps-button > *,
    ps-template-select ps-button > * {
        font-size: 0.8em;
    }

    .buttons-flex {
        flex-direction: column;
    }

    .buttons-flex div:first-of-type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .buttons-flex div:first-of-type ps-button {
        flex: 1 1 0;
    }

    .buttons-flex div:first-of-type ps-button + ps-button {
        margin-left: 1em;
    }

    .dialog,
    .dataform,
    .progress-modal {
        width: calc(100vw - 2em) !important;
        max-height: calc(100vh - 8em) !important;
        margin: 2em auto 0 auto;
        font-size: 16px;
    }

    .dialog-content,
    .dataform-content {
        padding: 1em;
        padding-top: 1.5em;
    }

    .progress-modal {
        min-width: initial;
        max-width: initial;
        width: calc(100% -2em);
    }

    .progress-bar {
        width: calc(100% - 2em);
        margin-left: 1em;
        margin-bottom: 1em;
    }

    .snackbar {
        margin: 0;
        width: 100vw;
        text-align: center;
    }

    .list-cards {
        font-size: 16px;
    }
}
